html body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Cutive Mono'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yarl__carousel img {
  pointer-events: none;
}


a {
  color: #b92a49;
  text-decoration: none;
}

#datos p {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}


#hero img {
  width: 100%;
  padding-bottom: 100px;
}

#section p {
  color: white;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}

#gerardo {
  width: 300px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  /* center */
  justify-content: center;
  align-items: center;
}

.grid-item1 {
  text-align: center;
}

.grid-item1 img {
  display: block;
  margin-inline: auto;
}

#publicaciones a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b92a49;
}

a {
  font-family: 'Bongee Inline';
}

#publicaciones {
  padding-top: 10px;
}

#publicaciones p {
  padding: 12px;
}

.logos-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 20px;
  background-color: black;
  padding-top: 50px;
}


.grid-item {
  text-align: center;

}

.logo {
  max-width: 20%;
}

@media (min-width: 350px) and (max-width: 500px) {
  .logos-container {
    grid-template-columns: 1fr;
    /* 1 columna */
  }

  .grid-container {
    grid-template-columns: 1fr;
    /* 1 columna */
  }

  #gerardo {
    width: 300px;
  }

  #section p {
    padding-left: 50px;
    padding-right: 50px;
  }

}


@media (min-width: 768px) and (max-width: 1180px) {

  .grid-container {
    grid-template-columns: 1fr;
    /* 1 columna */
  }

}